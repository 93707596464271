import React from 'react';

import liberalPolicy from '../../../../images/hitler/policys/liberalPolicy.png';
import fascistPolicy from '../../../../images/hitler/policys/fascistPolicy.png';

const Examine = ({ examine }) => {
    const POLICY = [
        liberalPolicy,
        fascistPolicy
    ];

    if(examine.length > 0) {
        return (
            <div className="absolute w-100 h-100 center">
                <div className="relative transform-30 center">
                    <div className="relative hitlerPolicy overflow-hidden">
                        <h3>These are the top 3 policys on the deck</h3>
                        {examine.map((policy, i) => (
                            <div key={i} className="d-inline hitlerExamine">
                                <img src={POLICY[policy]} alt="policy"/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
    else return null;
}
 
export default Examine;