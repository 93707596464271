import React from 'react';
import { useCookies } from 'react-cookie';

import './cookieWarning.css';

const CookieWarning = () => {
    const [cookies, setCookie] = useCookies(['acceptCookie']);
    if(cookies.acceptCookie !== 'true'){
        return (
            <div className="cookieWarning">
                <h2 className="d-inline">This site uses cookies to better enhance your expirience. Continuing on this page is agreeing to these cookies.</h2>
                <button className="d-inline btn" onClick={() => setCookie('acceptCookie', true, { path: '/' })}>Accept</button>
            </div>
        );
    }
    return null;
}
 
export default CookieWarning;