import React, { useState } from 'react';

import './chatBar.css';

const ChatBar = ({ sendMessage }) => {

    const [message, setMessage] = useState('');

    const doSendMessage = () => {
        sendMessage(message);
        setMessage('');
    }

    return (  
        <div>
            <input 
                type="text" 
                onChange={e => setMessage(e.target.value)} 
                placeholder="Type a message..."
                value={message}
                onKeyPress={e => (e.key === 'Enter' && message.length > 0) ? doSendMessage() : null}
                className="inputBar"
            />
            <button className="inputButton btn" onClick={e => (message.length > 0) ? doSendMessage() : null} >&gt;</button>
        </div>
    );
}
 
export default ChatBar;