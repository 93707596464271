import React from 'react';

import liberalMembership from '../../../../images/hitler/memberships/liberal.png';
import fascistMembership from '../../../../images/hitler/memberships/fascist.png';

const Membership = ({ membership }) => {
    const MEMBERSHIPS = [
        liberalMembership,
        fascistMembership
    ];

    if(membership !== -1){
        return <img className="hitlerCard" src={MEMBERSHIPS[membership]} alt="membership"/>;
    }
    else return null;
}
 
export default Membership;