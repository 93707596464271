import React from 'react';

import chancellor from '../../../../images/hitler/chancellor.png';
import president from '../../../../images/hitler/president.png';

const Gov = ({ gov }) => {
    const GOVS = [
        chancellor,
        president
    ];
    
    if(gov !== -1){
        return <img className="d-inline hitlerGov mt-n5 f-left ml-1" src={GOVS[gov]} alt="gov"/>;
    }
    else {
        return null;
    }
}
 
export default Gov;