import React from 'react';

import './player.css';

const Player = ({ player }) => {

    const ICONS = [
        {role: 'gamemaster', code: 'far fa-star'},
        {role: 'cpu', code: 'fas fa-laptop-code'},
        {role: 'fascist', code: 'far fa-user'},
        {role: 'hitler', code: 'fas fa-user'},
        {role: 'dead', code: 'fas fa-ban'},
        {role: 'president', code: 'fas fa-gopuram'},
        {role: 'chancellor', code: 'fas fa-balance-scale'},
        {role: 'vote', code: 'fas fa-vote-yea'},
    ];

    const getIcons = () => {
        let temp = [];

        for(let i = 0; i<ICONS.length; i++){
            if(player.roles.includes(ICONS[i].role)){
                temp.push(
                    <div key={i} className="tooltip d-inline">
                        <i className={"role-icon "+ICONS[i].code}></i>
                        <span className="tooltiptext">{ICONS[i].role}</span>
                    </div>
                );
            }
        }

        return temp;
    }

    return (  
        <div>
            <p className="d-inline" style={{color: player.color}}>{player.name} </p>
            {getIcons()}
        </div>
    );
}
 
export default Player;