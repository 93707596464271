import React from 'react';

import fascistboard1_0p from '../../../../images/hitler/boards/fascist/fascistboard1_0p.png';
import fascistboard1_1p from '../../../../images/hitler/boards/fascist/fascistboard1_1p.png';
import fascistboard1_2p from '../../../../images/hitler/boards/fascist/fascistboard1_2p.png';
import fascistboard1_3p from '../../../../images/hitler/boards/fascist/fascistboard1_3p.png';
import fascistboard1_4p from '../../../../images/hitler/boards/fascist/fascistboard1_4p.png';
import fascistboard1_5p from '../../../../images/hitler/boards/fascist/fascistboard1_5p.png';
import fascistboard1_6p from '../../../../images/hitler/boards/fascist/fascistboard1_6p.png';

import fascistboard2_0p from '../../../../images/hitler/boards/fascist/fascistboard2_0p.png';
import fascistboard2_1p from '../../../../images/hitler/boards/fascist/fascistboard2_1p.png';
import fascistboard2_2p from '../../../../images/hitler/boards/fascist/fascistboard2_2p.png';
import fascistboard2_3p from '../../../../images/hitler/boards/fascist/fascistboard2_3p.png';
import fascistboard2_4p from '../../../../images/hitler/boards/fascist/fascistboard2_4p.png';
import fascistboard2_5p from '../../../../images/hitler/boards/fascist/fascistboard2_5p.png';
import fascistboard2_6p from '../../../../images/hitler/boards/fascist/fascistboard2_6p.png';

import fascistboard3_0p from '../../../../images/hitler/boards/fascist/fascistboard3_0p.png';
import fascistboard3_1p from '../../../../images/hitler/boards/fascist/fascistboard3_1p.png';
import fascistboard3_2p from '../../../../images/hitler/boards/fascist/fascistboard3_2p.png';
import fascistboard3_3p from '../../../../images/hitler/boards/fascist/fascistboard3_3p.png';
import fascistboard3_4p from '../../../../images/hitler/boards/fascist/fascistboard3_4p.png';
import fascistboard3_5p from '../../../../images/hitler/boards/fascist/fascistboard3_5p.png';
import fascistboard3_6p from '../../../../images/hitler/boards/fascist/fascistboard3_6p.png';

const FascistBoard = ({ board }) => {
    
    const BOARDS = [
        [
            fascistboard1_0p,
            fascistboard1_1p,
            fascistboard1_2p,
            fascistboard1_3p,
            fascistboard1_4p,
            fascistboard1_5p,
            fascistboard1_6p
        ],
        [
            fascistboard2_0p,
            fascistboard2_1p,
            fascistboard2_2p,
            fascistboard2_3p,
            fascistboard2_4p,
            fascistboard2_5p,
            fascistboard2_6p
        ],
        [
            fascistboard3_0p,
            fascistboard3_1p,
            fascistboard3_2p,
            fascistboard3_3p,
            fascistboard3_4p,
            fascistboard3_5p,
            fascistboard3_6p
        ]
    ];

    if(board){
        return <img className="hitlerBoard" src={BOARDS[board.type][board.policys]} alt="fascistBoard"/>;
    }
    else return null;
}
 
export default FascistBoard;