import React from 'react';

const PlayerSelect = ({ playerSelect, setPlayerSelect, socket }) => {
    const SELECTORS = [
        'Please select 1 person to be your chancellor',
        'Please select 1 person to be the next president',
        'Please select 1 person to kill',
        'Please select 1 person to investigate'
    ];

    const getIcon = (player) => {
        if(player.enabled){
            return ' fas fa-user';
        }
        return ' far fa-user';
    }

    const selectPlayer = (name) => {
        socket.emit('gameData', {playerSelect: name}, () => {});
        setPlayerSelect(null);
    }

    if(playerSelect){
        return (
            <div className="absolute w-100 h-100 center">
                <div className="relative transform-30 center">
                    <div className="relative hitlerChancellor overflow-hidden">
                        <h3>{SELECTORS[playerSelect.type]}</h3>
                        {playerSelect.players.map((player, i) => (
                            <button key={i} className={player.enabled ? null : 'hitlerChancellorDisabled'} onClick={() => player.enabled ? selectPlayer(player.name) : null}>
                                <i className={"fa-7x"+getIcon(player)}></i>
                                <p style={{color: player.color}}>{player.name}</p>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
    else return null;
}
 
export default PlayerSelect;