import React from 'react';

import liberalMembership from '../../../../images/hitler/memberships/liberal.png';
import fascistMembership from '../../../../images/hitler/memberships/fascist.png';

const Investigate = ({ investigate }) => {
    const getMembership = () => {
        if(investigate.membership === 0){
            return (
                <React.Fragment>
                    <h3 className="color-blue">Liberal</h3>
                    <img src={liberalMembership} alt="liberalMembership"/>
                </React.Fragment>
            );
        }
        else if(investigate.membership === 1){
            return (
                <React.Fragment>
                    <h3 className="color-red">Fascist</h3>
                    <img src={fascistMembership} alt="fascistMembership"/>
                </React.Fragment>
            );
        }
    }

    if(investigate) {
        return (
            <div className="absolute w-100 h-100 center">
                <div className="relative transform-30 center">
                    <div className="relative hitlerInvestigate overflow-hidden">
                        <h3 className="d-inline">{investigate.name}'s political affiliation is </h3>
                        {getMembership()}
                    </div>
                </div>
            </div>
        );
    }
    else return null;
}
 
export default Investigate;