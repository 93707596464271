import React, { useState, useEffect } from 'react';

import liberal from '../../../../images/hitler/rolePreview/liberal.png';
import fascist from '../../../../images/hitler/rolePreview/fascist.png';
import hitler from '../../../../images/hitler/rolePreview/hitler.png';

const TTL = 2500;
const FADE = 500;

const RolePreview = ({rolePreview, setRolePreview}) => {
    const [time, setTime] = useState(-1);

    useEffect(() => {
        if(rolePreview !== -1){
            setTime(TTL);
        }
    }, [rolePreview]);

    useEffect(() => {
        const interval = setInterval(() => {
            if(time > -1 && rolePreview !== -1){
                setTime(time - 1);
                if(time === 0){
                    setRolePreview(-1);
                }
            }
        }, 1);
        return () => clearInterval(interval);
    }, [rolePreview, time, setRolePreview]);

    const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
    const invlerp = (x, y, a) => clamp((a - x) / (y - x));
    
    const getStyle = () => {
        return {opacity: invlerp(0, FADE, time)};
    }

    const getImage = () => {
        if(rolePreview === 0){
            return liberal;
        }
        else if(rolePreview === 1){
            return fascist;
        }
        else if(rolePreview === 2){
            return hitler;
        }
    }

    if(rolePreview !== -1){
        return (
            <div className="absolute w-100 h-100 bg-black" style={getStyle()}>
                <img className="rolePreview" src={getImage()} alt="rolePreview"/>
            </div>
        );
    }
    return null;
}
 
export default RolePreview;