import React from 'react';

const Timer = ({ timer }) => {
    if(timer !== -1) {
        return (  
            <h1 className="d-inline f-right hitlerTimer mr-1">{timer}</h1>
        );
    }
    else return null;
}
 
export default Timer;