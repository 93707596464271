import React from 'react';

const PreGame = ({ pregame, socket }) => {
    if(pregame){
        if(pregame.state === 0){
            return (  
                <div className="absolute w-100 h-100">
                    <div className="relative transform-30 center">
                        <h1>Waiting for more players to join (min 5 players)...</h1>
                        <p>In room: {`${pregame.players}/10`}</p>
                    </div>
                </div>
            );
        }
        else if(pregame.state === 1){
            return (  
                <div className="absolute w-100 h-100">
                    <div className="relative transform-30 center">
                        <h1>Waiting for the gamemaster to start the game...</h1>
                        <p>In room: {`${pregame.players}/10`}</p>
                    </div>
                </div>
            );
        }
        else if(pregame.state === 2){
            return (  
                <div className="absolute w-100 h-100">
                    <div className="relative transform-30 center">
                        <h1>Click 'Start' when everyone is ready...</h1>
                        <p>In room: {`${pregame.players}/10`}</p>
                        <button className="hitlerStartBtn" onClick={() => socket.emit('gameData', {gameStart: true}, () => {})}>Start</button>
                    </div>
                </div>
            );
        }
    }
    else return null;
}
 
export default PreGame;