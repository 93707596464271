import React from 'react';

import ja from '../../../../images/hitler/ja.png';
import nein from '../../../../images/hitler/nein.png';

const Vote = ({ voteSelect, setVoteSelect, socket }) => {

    const selectVote = (v) => {
        socket.emit('gameData', {vote: v}, () => {});
        setVoteSelect(null);
    }

    if(voteSelect){
        return (
            <div className="absolute w-100 h-100 center">
                <div className="relative transform-30 center">
                    <div className="relative hitlerVote overflow-hidden">
                        <h3>Cast your vote for {voteSelect.president} as president and {voteSelect.chancellor} as chancellor</h3>
                        <button className="d-inline transparent-btn" onClick={() => selectVote(true)}>
                            <div className="d-block">
                                <img className="d-block" src={ja} alt="ja"/>
                            </div>
                        </button>
                        <button className="d-inline transparent-btn" onClick={() => selectVote(false)}>
                            <div className="d-block">
                                <img className="d-block" src={nein} alt="nein"/>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    else return null;
}
 
export default Vote;