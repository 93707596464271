import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import './code.css';

const Code = ({ code }) => {
    return (  
        <div className="d-inline">
            <CopyToClipboard text={`${window.location.origin}${window.location.pathname}?room=${code}`} onCopy={() => alert("Link copied to clipboard!")}>
                <button className="roomCode">{code}</button>
            </CopyToClipboard>
        </div>
    );
}
 
export default Code;