import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Landing from './components/landing/landing';
import FourOFour from './components/404/404';
import Error from './components/error/error';
import SecretHitlerLoader from './components/hitler/secretHitlerLoader';

import './App.css';

import {SERVERS} from './config';

const App = () => {
    
    const [cookies, setCookie] = useCookies(['server']);
    let tempServer = '';
    if(cookies.server !== undefined){
        tempServer = cookies.server;
    }
    else{
        tempServer = SERVERS[0].address;
    }
    const [server, setServer] = useState(tempServer);

    useEffect(() => {
        setCookie('server', server, { path: '/' });
    },[server, setCookie]);

    return (
        <Router>
            <Switch>
                <Route path="/" exact render={({ location }) => <Landing location={location} server={server} setServer={setServer} SERVERS={SERVERS} />} />
                <Route path="/hitler" render={({ location }) => <SecretHitlerLoader location={location} server={server} />}/>
                <Route path="/error" exact component={Error} />
                <Route component={FourOFour} />
            </Switch>
        </Router>
    );
};

//secret_hitler
//CAH
//uno
//settlers of catan
 
export default App;