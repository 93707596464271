import React, { useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './landing.css';

import Selector from './selector/selector';
import HitlerStart from './landingPages/hitlerStart';
import More from './landingPages/more';
import ServerOptions from './serverOptions/serverOptions';
import CookieWarning from './cookieWarning/cookieWarning';

const Landing = ({ server, setServer, SERVERS }) => {
    const [serverStat, setServerStat] = useState(false);
    const [page, setPage] = useState(1);
    
    const PAGES = [
        <HitlerStart serverStat={serverStat} setServerStat={setServerStat} server={server} />,
        <More />
    ];

    return (
        <div className="center">
            <TransitionGroup>
                <CSSTransition key={page} timeout={{ enter: 100, exit: 100 }} classNames={'fade'}>
                    <div className="absolute w-100 h-100">
                        {PAGES[page-1]}
                    </div>
                </CSSTransition>
            </TransitionGroup>
            <Selector PAGES={PAGES.length} page={page} setPage={setPage} />
            <ServerOptions server={server} setServer={setServer} SERVERS={SERVERS} />
            <CookieWarning />
        </div>
    );
}
 
export default Landing;