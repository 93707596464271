import React from 'react';

import ja from '../../../../images/hitler/ja.png';
import nein from '../../../../images/hitler/nein.png';

const Veto = ({ veto, setVeto, socket }) => {

    const selectVeto = (v) => {
        socket.emit('gameData', {veto: v}, () => {});
        setVeto(null);
    }

    if(veto){
        return (
            <div className="absolute w-100 h-100 center">
                <div className="relative transform-30 center">
                    <div className="relative hitlerVote overflow-hidden">
                        <h3>{veto} wants to veto the current policys. Do you agree?</h3>
                        <button className="d-inline transparent-btn" onClick={() => selectVeto(true)}>
                            <div className="d-block">
                                <img className="d-block" src={ja} alt="ja"/>
                            </div>
                        </button>
                        <button className="d-inline transparent-btn" onClick={() => selectVeto(false)}>
                            <div className="d-block">
                                <img className="d-block" src={nein} alt="nein"/>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    else return null;
}
 
export default Veto;