import React from 'react';

import liberalboard0p0f from '../../../../images/hitler/boards/liberal/liberalboard0p0f.png';
import liberalboard0p1f from '../../../../images/hitler/boards/liberal/liberalboard0p1f.png';
import liberalboard0p2f from '../../../../images/hitler/boards/liberal/liberalboard0p2f.png';
import liberalboard0p3f from '../../../../images/hitler/boards/liberal/liberalboard0p3f.png';

import liberalboard1p0f from '../../../../images/hitler/boards/liberal/liberalboard1p0f.png';
import liberalboard1p1f from '../../../../images/hitler/boards/liberal/liberalboard1p1f.png';
import liberalboard1p2f from '../../../../images/hitler/boards/liberal/liberalboard1p2f.png';
import liberalboard1p3f from '../../../../images/hitler/boards/liberal/liberalboard1p3f.png';

import liberalboard2p0f from '../../../../images/hitler/boards/liberal/liberalboard2p0f.png';
import liberalboard2p1f from '../../../../images/hitler/boards/liberal/liberalboard2p1f.png';
import liberalboard2p2f from '../../../../images/hitler/boards/liberal/liberalboard2p2f.png';
import liberalboard2p3f from '../../../../images/hitler/boards/liberal/liberalboard2p3f.png';

import liberalboard3p0f from '../../../../images/hitler/boards/liberal/liberalboard3p0f.png';
import liberalboard3p1f from '../../../../images/hitler/boards/liberal/liberalboard3p1f.png';
import liberalboard3p2f from '../../../../images/hitler/boards/liberal/liberalboard3p2f.png';
import liberalboard3p3f from '../../../../images/hitler/boards/liberal/liberalboard3p3f.png';

import liberalboard4p0f from '../../../../images/hitler/boards/liberal/liberalboard4p0f.png';
import liberalboard4p1f from '../../../../images/hitler/boards/liberal/liberalboard4p1f.png';
import liberalboard4p2f from '../../../../images/hitler/boards/liberal/liberalboard4p2f.png';
import liberalboard4p3f from '../../../../images/hitler/boards/liberal/liberalboard4p3f.png';

import liberalboard5p0f from '../../../../images/hitler/boards/liberal/liberalboard5p0f.png';

const LiberalBoard = ({ board }) => {
    const BOARDS = [
        [
            liberalboard0p0f,
            liberalboard0p1f,
            liberalboard0p2f,
            liberalboard0p3f
        ],
        [
            liberalboard1p0f,
            liberalboard1p1f,
            liberalboard1p2f,
            liberalboard1p3f
        ],
        [
            liberalboard2p0f,
            liberalboard2p1f,
            liberalboard2p2f,
            liberalboard2p3f
        ],
        [
            liberalboard3p0f,
            liberalboard3p1f,
            liberalboard3p2f,
            liberalboard3p3f
        ],
        [
            liberalboard4p0f,
            liberalboard4p1f,
            liberalboard4p2f,
            liberalboard4p3f
        ],
        [liberalboard5p0f]
    ];

    if(board){
        return <img className="hitlerBoard" src={BOARDS[board.policys][board.fails]} alt="liberalBoard"/>;
    }
    else return null;
}
 
export default LiberalBoard;