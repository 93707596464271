import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {CLIENT_VERSION} from '../../../config';

import './serverOptions.css';

const ServerOptions = ({ server, setServer, SERVERS }) => {
    const [active, setActive] = useState(false);
    const [newCustomServer, setNewCustomServer] = useState('');
    const [activeServerIndex, setActiveServerIndex] = useState(0);

    const [serverVersion, setServerVersion] = useState('');
    useEffect(() => {
        axios.get(server+'/version')
        .then(data => {
            setServerVersion(data.data);
        })
        .catch(err => {
            console.log(err)
            setServerVersion('null');
        });
    }, [server]);

    const onActiveSwitch = () => {
        const aci = SERVERS.findIndex(s => s.address === server);
        setActiveServerIndex(aci);
        if(aci === -1){
            setNewCustomServer(server);
        }
        setActive(!active);
    }

    const getNameOfServer = () => {
        if(activeServerIndex !== -1){
            return SERVERS[activeServerIndex].name;
        }   
        else {
            return 'custom-server';
        }
    }

    const onActiveServerChange = (value) => {
        if(value === -1){
            setActiveServerIndex(value);
            if(newCustomServer !== ''){
                setServer(newCustomServer);
            }
        }
        else{
            setActiveServerIndex(value);
            setServer(SERVERS[value].address);
        }
    }
    
    const getCustom = () => {
        if(activeServerIndex === -1){
            return(
                <div className="customServerContainer">
                    <input type="text" value={newCustomServer} onChange={e => setNewCustomServer(e.target.value)} />
                    <button className="btn setCustomServerBtn" onClick={() => setServer(newCustomServer)}>Set Server</button>
                </div>
            );
        }
        return null;
    }

    if(active){
        return (  
            <div className="mt-10 ml-10 absolute w-fit h-fit left">
                <button className="transparent-btn optionsBtn" onClick={() => onActiveSwitch()}><i className="fas fa-cogs fa-5x"></i></button>
                <p className="d-inline version-info">{`Server: [V${serverVersion}] | Client: [V${CLIENT_VERSION}]`}</p>
                <div className="optionsContainer">
                    <p>{`Current Server: ${getNameOfServer()}`}</p>
                    <select id="servers" name="servers" value={activeServerIndex} onChange={e => onActiveServerChange(parseInt(e.target.value))}>
                        {SERVERS.map((s, i) => <option key={i} value={i}>{s.name}</option>)}
                        <option value={-1}>custom-server</option>
                    </select>
                    {getCustom()}
                </div>
            </div>
        );
    }
    else{
        return (  
            <div className="mt-10 ml-10 absolute w-fit h-fit">
                <button className="transparent-btn optionsBtn" onClick={() => onActiveSwitch()}><i className="fas fa-cogs fa-5x"></i></button>
                <p className="d-inline version-info">{`Server: [V${serverVersion}] | Client: [V${CLIENT_VERSION}]`}</p>
            </div>
        );
    }
}
 
export default ServerOptions;