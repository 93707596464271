import React from 'react';

import liberalPolicy from '../../../../images/hitler/policys/liberalPolicy.png';
import fascistPolicy from '../../../../images/hitler/policys/fascistPolicy.png';

const PolicySelect = ({ policySelect, setPolicySelect, socket }) => {
    const POLICY = [
        liberalPolicy,
        fascistPolicy
    ];

    const selectPolicy = (i) => {
        socket.emit('gameData', {policySelect: i}, () => {});
        setPolicySelect(null);
    }

    if(policySelect !== null) {
        return (
            <div className="absolute w-100 h-100 center">
                <div className="relative transform-30 center">
                    <div className="relative hitlerPolicy overflow-hidden">
                        <h3>{(policySelect.policys.length > 2) ? 'Please select 1 policy to discard. The others will move on.' : 'Please select a policy to enact. The other will be discarded.'}</h3>
                        {policySelect.policys.map((policy, i) => (
                            <div className="d-inline" key={i}>
                                <button className="transparent-btn" onClick={() => selectPolicy(i)}>
                                    <div className="d-block">
                                        <img className="d-block" src={POLICY[policy]} alt="policy"/>
                                    </div>
                                </button>
                            </div>
                        ))}
                        {policySelect.veto ? <button id="hitlerVeto" className="d-block" onClick={() => selectPolicy(-1)}>Veto</button> : null}
                    </div>
                </div>
            </div>
        );
    }
    else return null;
}
 
export default PolicySelect;