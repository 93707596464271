import React from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';

import Message from './message/message';
import ChatBar from './chatBar/chatBar';

import './chat.css';

const Chat = ({ messages, sendMessage }) => {
    return (  
        <div>
            <ScrollToBottom className="chat">
                {messages.map((message, i) => <Message key={i} username={message.username} msg={message.msg} color={message.color} admin={message.admin} />)}
            </ScrollToBottom>
            <ChatBar sendMessage={sendMessage} />
        </div>
    );
}
 
export default Chat;