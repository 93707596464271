import React, { useState, useEffect } from 'react';

import './gameOptions.css';

const GameOptions = ({ socket, currentOptions }) => { 
    const [active, setActive] = useState(false);
    const [newTimers, setNewTimers] = useState(null);
    const [newColor, setNewColor] = useState(null);

    useEffect(() => {
        if(currentOptions !== null){
            setNewTimers(currentOptions.timers.timers);
            setNewColor(currentOptions.color);
        }
    }, [active, currentOptions]);

    const onChangeTimers = () => {
        socket.emit('gameData', {setTimers: newTimers}, () => {});
        setActive(false);
    }

    const onChangeColor = () => {
        socket.emit('gameData', {setColor: newColor}, () => {});
        setActive(false);
    }
    
    if(currentOptions !== null){

        if(active){
            let options = [];

            if(currentOptions.timers.allowChange){
                options.push(
                    <div key={0} className="optionSet">
                        <h3 className="m-0">Timer Settings</h3>
                        <div className="timerSet">
                            <input value={newTimers.review} onChange={e => {
                                    let temp = {...newTimers};
                                    temp.review = parseInt(e.target.value);
                                    setNewTimers(temp);
                                }} type="number" />
                            <label>Review</label>
                        </div>
                        <div className="timerSet">
                            <input value={newTimers.playerPicker} onChange={e => {
                                let temp = {...newTimers};
                                temp.playerPicker = parseInt(e.target.value);
                                setNewTimers(temp);
                            }} type="number" />
                            <label>Player Select</label>
                        </div>
                        <div className="timerSet">
                            <input value={newTimers.vote} onChange={e => {
                                let temp = {...newTimers};
                                temp.vote = parseInt(e.target.value);
                                setNewTimers(temp);
                            }} type="number" />
                            <label>Vote</label>
                        </div>
                        <div className="timerSet">
                            <input value={newTimers.drawPolicys} onChange={e => {
                                let temp = {...newTimers};
                                temp.drawPolicys = parseInt(e.target.value);
                                setNewTimers(temp);
                            }} type="number" />
                            <label>Draw Policys</label>
                        </div>
                        <div className="timerSet">
                            <input value={newTimers.policySelect} onChange={e => {
                                let temp = {...newTimers};
                                temp.policySelect = parseInt(e.target.value);
                                setNewTimers(temp);
                            }} type="number" />
                            <label>Policy Select</label>
                        </div>
                        <button className="btn timerSetButton" onClick={() => onChangeTimers()}>Update Settings</button>
                    </div>
                );
            }
            else{
                options.push(
                    <div key={0} className="optionSet">
                        <h3 className="m-0">Timer Settings</h3>
                        <div className="timerSet">
                            <p className="d-inline">{newTimers.review}</p>
                            <label className="d-inline">Review</label>
                        </div>
                        <div className="timerSet">
                            <p className="d-inline">{newTimers.playerPicker}</p>
                            <label className="d-inline">Player Select</label>
                        </div>
                        <div className="timerSet">
                            <p className="d-inline">{newTimers.vote}</p>
                            <label className="d-inline">Vote</label>
                        </div>
                        <div className="timerSet">
                            <p className="d-inline">{newTimers.drawPolicys}</p>
                            <label className="d-inline">Draw Policys</label>
                        </div>
                        <div className="timerSet">
                            <p className="d-inline">{newTimers.policySelect}</p>
                            <label className="d-inline">Policy Select</label>
                        </div>
                    </div>
                );
            }

            options.push(
                <div key={1} className="optionSet">
                    <h3 className="m-0">Name Color</h3>
                    <div className="colorSet">
                        <input type="color" value={newColor} onChange={e => setNewColor(e.target.value)} />
                        <button className="btn" onClick={() => onChangeColor()}>Update Color</button>
                    </div>
                </div>
            );

            options.push(
                <div key={2} className="optionSet">
                    <a href="https://secrethitler.com/assets/Secret_Hitler_Rules.pdf" rel="noopener noreferrer" target="_blank">Secret Hitler Rules</a>
                </div>
            );

            return (
                <div className="mt-10 ml-10 absolute w-fit h-fit left">
                    <button className="transparent-btn optionsBtn" onClick={() => setActive(!active)}><i className="fas fa-cogs fa-5x"></i></button>
                    <div className="optionsContainer">
                        {options}
                    </div>
                </div>
            );
        }
        else{
            return (
                <div className="mt-10 ml-10 absolute w-fit h-fit left">
                    <button className="transparent-btn optionsBtn" onClick={() => setActive(!active)}><i className="fas fa-cogs fa-5x"></i></button>
                </div>
            );
        }
    }
    return null;
}
 
export default GameOptions;