import React, { useState } from 'react';

import policyPile from '../../../../images/hitler/policyPile/normal.png';
import policyPileHover from '../../../../images/hitler/policyPile/selected.png';

const DrawPile = ({ draw, setDraw, socket }) => {
    const DRAW = [
        policyPile,
        policyPileHover
    ];

    const [drawPileState, setDrawPileState] = useState(0);

    const drawCard = () => {
        socket.emit('gameData', {drawPolicys: true}, () => {});
        setDraw(false);
    }

    if(draw){
        return (  
            <button className="transparent-btn drawPile" onClick={() => drawCard()}>
                <img 
                    className="img"
                    src={DRAW[drawPileState]}
                    alt="drawPile"
                    onMouseOver={() => setDrawPileState(1)}
                    onMouseLeave={() => setDrawPileState(0)}
                    onClick={() => drawCard()}
                />
            </button>
        );
    }
    else return null;
}
 
export default DrawPile;