import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import io from 'socket.io-client';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';

import Name from '../common/name/name';
import SecretHitler from './secretHitler';

let socket;

const SecretHitlerLoader = ({ location, server }) => {
    const [username, setUsername] = useState(null);
    const [room, setRoom] = useState(null);
    const [valid, setValid] = useState(false);
    const [kick, setKick] = useState(null);

    useEffect(() => {

        axios.get(server)
            .then(() => {
                socket = io(server);

                if(room === null){
                    const newRoom = queryString.parse(location.search).room;
                    setRoom(newRoom);
                    if(typeof newRoom === 'undefined'){
                        socket.emit('requestRoom', {game: 0}, ({ error, code }) => {
                            if(error){
                                setKick('noRoomsAvailable');
                            }
                            else{
                                setRoom(code);
                                setValid(true);
                            }
                        });
                    }
                    else{
                        socket.emit('checkRoom', {game: 0, room: newRoom}, (isValid) => {
                            if(!isValid){
                                setKick('roomDoesNotExist');
                            }
                            else{
                                setValid(true);
                            }
                        });
                    }
                }
                

                return () => {
                    socket.emit('disconnect');

                    socket.off();
                }
            })
            .catch(err => console.log(err));

    }, [location.search, room, server]);

    if(kick) {
        return <Redirect to={`/error?err=${kick}`} />;
    }
    if(room === null || typeof room === 'undefined' || !valid){
        return (
        <div>
            <p>Contacting server</p>
            <Link to={`/`}><button className="btn">Change Server</button></Link>
        </div>
        );
    }
    if(username === null){
        return <Name setUsername={setUsername} room={room} />;
    }

    return <SecretHitler socket={socket} username={username} room={room} setKick={setKick} />;  
}
 
export default SecretHitlerLoader;