import React from 'react';

import './selector.css';

const Selector = ({ PAGES, page, setPage }) => {

    const getSelectors = () => {
        let temp = [];
        for(let i = 1; i<=PAGES; i++){
            if(i === page){
                temp.push(<i key={i} className="fas fa-circle fa-3x d-inline selectorIcon"></i>);
            }
            else {
                temp.push(<i key={i} className="far fa-circle fa-3x d-inline selectorIcon"></i>);
            }
        }
        return temp;
    }

    return (  
        <div className="mt-10 absolute w-100 h-fit">
            <button className={(page <= 1) ? 'd-inline-block selectorButton' : 'd-inline-block selectorButton btn'} onClick={() => (page > 1) ? setPage(page - 1) : null} disabled={page <= 1 ? true : false}>&lt;</button>
            {getSelectors()}
            <button className={(page >= PAGES) ? 'd-inline-block selectorButton' : 'd-inline-block selectorButton btn'} onClick={() => (page < PAGES) ? setPage(page + 1) : null} disabled={page >= PAGES ? true : false}>&gt;</button>
        </div>
    );
}
 
export default Selector;