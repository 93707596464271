import React from 'react';

import './message.css';

const Message = ({ username, msg, color, admin }) => {
    if(admin){
        return(
            <div className="chatMsg">
                <p className="admin-green">{msg}</p>
            </div>
        );
    }
    else{
        return (  
            <div className="chatMsg">
                <p className={"d-inline"} style={{color: color}}>{username}: </p>
                <p className="d-inline color-black">{msg}</p>
            </div>
        );
    }
}
 
export default Message;