import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import './landingPages.css';

import hitlerBG from '../../../images/hitler-bg.png';

const HitlerStart = ({ serverStat, setServerStat, server }) => {
    const [room, setRoom] = useState('');
    const [gameData, setGameData] = useState({inUse: null, total: null});

    useEffect(() => {
        axios.get(server+'/gamedata')
            .then(data => {
                setServerStat(true);
                setGameData({
                    inUse: data.data[0].inUse,
                    total: data.data[0].total
                });
            })
            .catch(err => {
                console.log(err)
                setGameData({
                    inUse: 0,
                    total: 0
                });
                setServerStat(false);
            });
    }, [setServerStat, server]);

    const getServerStat = () => {
        if(serverStat){
            return (
                <p className="color-green">Server Online</p>
            );
        }
        else{
            return (
                <p className="color-red">Server Offline</p>
            );
        }
    }

    return (
        <div className="landingGameContainer" style={{backgroundImage: "url(" + hitlerBG + ")"}}>
            <div className="centered-40 landingGame">
                <h1>Secret Hitler</h1>
                {getServerStat()}
                <p>Rooms in use {`${gameData.inUse}/${gameData.total}`}</p>
                <input type="text" placeholder="Room (if applicable)" minLength="4" maxLength="4" value={room} onChange={e => setRoom(e.target.value.toUpperCase())} />
                <div>
                    <Link to={`/hitler`}><button className="btn">New Game</button></Link>
                    <Link to={`/hitler?room=${room}`} onClick={e => (room.length === 4) ? null : e.preventDefault()}><button className="btn">Join Game</button></Link>
                </div>
            </div>
        </div>  
    );
}
 
export default HitlerStart;