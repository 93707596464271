import React from 'react';

import './landingPages.css';

const More = () => {
    return (
        <div className="relative transform-30">
            <h1>More games coming soon...</h1>
        </div>
    );
}
 
export default More;