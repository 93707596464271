import React from 'react';

const Dead = ({ dead }) => {
    if(dead) {
        return (  
            <h1 className="d-inline hitlerDead">You are dead</h1>
        );
    }
    else return null;
}
 
export default Dead;