import React from 'react';
import { Link } from 'react-router-dom';

import './404.css';

const FourOFour = () => (
    <div className="fourofourContainer">
        <div className="fourofour centered-40">
            <h1>404 Page Not Found</h1>
            <Link to="/"><button className="btn">Return Home</button></Link>
        </div>
    </div>
);
 
export default FourOFour;