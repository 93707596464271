import React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import './error.css';

const Error = ({location}) => {
    return (  
        <div className="errorContainer">
            <div className="error centered-40">
                <h1>The game encountered an error!</h1>
                <p>Error: {queryString.parse(location.search).err}</p>
                <Link to="/"><button className="btn">Return Home</button></Link>
            </div>
        </div>
    );
}
 
export default Error;