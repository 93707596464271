import React from 'react';

const Win = ({ win, setWin, socket }) => {
    const sendReset = () => {
        socket.emit('gameData', {reset: true}, () => {});
        setWin(null);
    }

    if(win !== null){
        let temp = [];

        if(win.draw){
            temp.push(
                <h1 key={100} className="color-blue">Draw</h1>
            );
        }
        else{
            if(win.win){
                temp.push(
                    <h1 key={0} className="color-blue">Winner Winner</h1>
                );
            }
            else {
                temp.push(
                    <h1 key={0} className="color-red">Defeat</h1>
                );
            }
    
            if(win.winner === 0){
                temp.push(
                    <h3 key={1}>Liberals Win</h3>
                );
            }
            else if(win.winner === 1){
                temp.push(
                    <h3 key={1}>Fascists Win</h3>
                );
            }
        }

        temp.push(
            <div key={2}>
                <div className="d-inline-block winPlayer">
                    <h4>Hitler</h4>
                    <i className="fas fa-user fa-7x"></i>
                    <p style={{color: win.hitler.color}}>{win.hitler.name}</p>
                </div>
                {win.fascists.map((fascist, i) => (
                    <div key={i} className="d-inline-block winPlayer">
                        <h4>Fascist</h4>
                        <i className="fas fa-user fa-7x"></i>
                        <p style={{color: fascist.color}}>{fascist.name}</p>
                    </div>
                ))}
            </div>
        );

        if(win.gamemaster){
            temp.push(
                <button key={3} onClick={() => sendReset()}>
                    New Game
                </button>
            );
        }
        else {
            temp.push(
                <p key={3} className="mt-10 color-white">
                    Waiting for gamemaster...
                </p>
            );
        }

        return(
            <div className="absolute w-100 h-100 center">
                <div className="relative transform-30 center">
                    <div className="relative hitlerWin overflow-hidden">
                        {temp}
                    </div>
                </div>
            </div>
        );
    }
    else return null;
}
 
export default Win;