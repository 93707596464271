import React from 'react';

import liberalRole from '../../../../images/hitler/roles/liberal.png';
import fascistRole from '../../../../images/hitler/roles/fascist.png';
import hitlerRole from '../../../../images/hitler/roles/hitler.png';

const Role = ({ role }) => {
    const ROLES = [
        liberalRole,
        fascistRole,
        hitlerRole
    ];

    if(role !== -1){
        return <img className="hitlerCard" src={ROLES[role]} alt="role"/>;
    }
    else return null;
}
 
export default Role;