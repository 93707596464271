import React from 'react';

const ElectionResults = ({ electionResults }) => {
    const getClass = (vote) => {
        if(vote){
            return "fas fa-user fa-7x color-green";
        }
        else{
            return "fas fa-user fa-7x color-red";
        }
    }

    if(electionResults === null) return null;
    return (  
        <div className="absolute w-100 h-100 center">
            <div className="relative transform-30 center">
                <div className="relative hitlerChancellor overflow-hidden">
                    <h1>The election of {electionResults.president} and {electionResults.chancellor} has {(electionResults.passed) ? 'passed!' : 'failed!'}</h1>
                    <h3>{electionResults.ja} to {electionResults.nein}</h3>
                    {electionResults.votes.map((player, i) => (
                            <div key={i} className="d-inline-block electionResultPlayer">
                                <i className={getClass(player.vote)}></i>
                                <p style={{color: player.color}}>{player.name}</p>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}
 
export default ElectionResults;