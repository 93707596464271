import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import './name.css';

const Name = ({ setUsername, room }) => {
    const [newUsername, setNewUsername] = useState('');

    return (  
        <div className="container nameScreen">
            <div className="centered-30 nameContainer text-align-center">
                <Helmet><title>Enter Username</title></Helmet>
                <h1>Connecting to room {room}</h1>
                <input 
                    className="nameInput"
                    type="text" 
                    placeholder="Enter name..." 
                    onChange={e => setNewUsername(e.target.value)} 
                    onKeyPress={e => (e.key === 'Enter' && newUsername.length > 0) ? setUsername(newUsername) : null}
                />
                <button 
                    className="nameEnterButton btn" 
                    onClick={() => (newUsername.length > 0) ? setUsername(newUsername) : null} >
                    Enter
                </button>
            </div>
        </div>
    );
}
 
export default Name;