import React from 'react';
import { useCookies } from 'react-cookie';

import Code from './code/code';
import PlayerList from './playerList/playerList';
import Chat from './chat/chat';

import './sidebar.css';

import CloseIcon from '../../../icons/closeIcon.png';

const SideBar = ({ code, playerList, roomStats, messages, sendMessage }) => {
    const [cookies, setCookie] = useCookies(['theme']);
    let theme = 'light-theme';
    if(cookies.theme !== undefined){
        theme = cookies.theme;
    }
    return (  
        <div className={`d-inline-block sidebar ${theme}`}>
            <div className="codeDisplay">
                <Code code={code} />
                <a href="/" className="d-inline float-right mr-10"><img src={CloseIcon} alt="leave"/></a>
            </div>
            <PlayerList playerList={playerList} roomStats={roomStats} theme={theme} setCookie={setCookie} />
            <Chat messages={messages} sendMessage={sendMessage} />
        </div>
    );
}
 
export default SideBar;