import React, { useState, useEffect } from 'react';

import './game.css';
import Timer from './parts/timer';
import Gov from './parts/gov';
import DrawPile from './parts/drawPile';
import LiberalBoard from './parts/liberalBoard';
import FascistBoard from './parts/fascistBoard';
import Role from './parts/role';
import Membership from './parts/membership';
import Vote from './parts/vote';
import PolicySelect from './parts/policySelect';
import PlayerSelect from './parts/playerSelect';
import PreGame from './parts/preGame';
import Win from './parts/win';
import Veto from './parts/veto';
import Examine from './parts/examine';
import Investigate from './parts/investigate';
import ElectionResults from './parts/electionResults';
import Dead from './parts/dead';
import GameOptions from './parts/gameOptions/gameOptions';
import RolePreview from './parts/rollPreview';

const Game = ({ socket }) => {

    //Boards
    const [liberalBoard, setLiberalBoard] = useState(null); //{policys: 0, fails: 0}
    const [fascistBoard, setFascistBoard] = useState(null); //{type: 0, policys: 0}

    //Roles
    const [role, setRole] = useState(-1);
    const [membership, setMembership] = useState(-1);
    const [gov, setGov] = useState(-1);

    //Gameplay
    const [draw, setDraw] = useState(false);
    const [timer, setTimer] = useState(-1);
    const [pregame, setPregame] = useState(null); //{state: 0, players: 0}
    const [voteSelect, setVoteSelect] = useState(null); //{president: 'coehler', chancellor: 'jordanna'}
    const [playerSelect, setPlayerSelect] = useState(null); //{type: 0, players: [{name: 'coehler', enabled: true, color: 'rgb(10,60,200)'}, {name: 'jordanna', enabled: false, color: 'rgb(100,60,20)'}, {name: 'micheal', enabled: true, color: 'rgb(155,160,20)'}]}
    const [policySelect, setPolicySelect] = useState(null); //[0, 0, 1] or [0, 1] {veto: false, policys: [0, 1, 1]}
    const [win, setWin] = useState(null); //{win: true, winner: 0, gamemaster: true, hitler: {name: "Cameron", color: "rgb(255,0,100)"}, fascists: [{name: "Cameron", color: "rgb(255,0,100)"}, {name: "Cameron", color: "rgb(255,0,100)"}]}
    const [veto, setVeto] = useState(null); //'coehler'
    const [examine, setExamine] = useState([]); //[0, 0, 1]
    const [investigate, setInvestigate] = useState(null); //{name: 'coehler', membership: 0}
    const [electionResults, setElectionResults] = useState(null); //{president: 'coehler', chancellor: 'catz', passed: true, ja: 2, nein: 3, votes: [{name: 'coehler', vote: true, color: 'rgb(100,60,20)}, {name: 'coehler', vote: false, color: 'rgb(100,60,20)}, {name: 'coehler', vote: true, color: 'rgb(100,60,20)}, {name: 'coehler', vote: true, color: 'rgb(100,60,20)}, {name: 'coehler', vote: false, color: 'rgb(100,60,20)}, {name: 'coehler', vote: true, color: 'rgb(100,60,20)}, {name: 'coehler', vote: true, color: 'rgb(100,60,20)}, {name: 'coehler', vote: false, color: 'rgb(100,60,20)}, {name: 'coehler', vote: true, color: 'rgb(100,60,20)}]}
    const [dead, setDead] = useState(false);
    const [options, setOptions] = useState(null); //{color: '#AF4B15', timers: {allowChange: true, timers: {review: 15, playerPicker: 45, vote: 60, drawPolicys: 30, policySelect: 30}}}
    const [rolePreview, setRolePreview] = useState(-1);

    useEffect(() => {
        socket.on('liberalBoard', (liberalBoard) => {
            setLiberalBoard(liberalBoard);
        });

        socket.on('fascistBoard', (fascistBoard) => {
            setFascistBoard(fascistBoard);
        });

        socket.on('role', (role) => {
            setRole(role);
        });

        socket.on('membership', (membership) => {
            setMembership(membership);
        });

        socket.on('gov', (gov) => {
            setGov(gov);
        });

        socket.on('draw', (draw) => {
            setDraw(draw);
        });

        socket.on('timer', (timer) => {
            setTimer(timer);
        });

        socket.on('pregame', (pregame) => {
            setPregame(pregame);
        });

        socket.on('options', (options) => {
            setOptions(options);
        });
        
        socket.on('voteSelect', (voteSelect) => {
            setVoteSelect(voteSelect);
        });

        socket.on('playerSelect', (playerSelect) => {
            setPlayerSelect(playerSelect);
        });

        socket.on('policySelect', (policySelect) => {
            setPolicySelect(policySelect);
        });

        socket.on('win', (win) => {
            setWin(win);
        });
        
        socket.on('veto', (veto) => {
            setVeto(veto);
        });

        socket.on('examine', (examine) => {
            setExamine(examine);
        });

        socket.on('investigate', (investigate) => {
            setInvestigate(investigate);
        });

        socket.on('electionResults', (electionResults) => {
            setElectionResults(electionResults);
        });

        socket.on('dead', (dead) => {
            setDead(dead);
        });

        socket.on('rolePreview', (role) => {
            setRolePreview(role);
        });
    }, [socket]);

    return (  
        <div>
            <div className="absolute w-100 h-100">
                <div className="relative section-10 center overflow-hidden">
                    <Gov gov={gov} />
                    <Dead dead={dead} />
                    <Timer timer={timer} />
                </div>

                <div className="relative section-90 center">
                    <div className="relative d-inlineblock h-100x hitlerSidebar">
                        <div className="relative h-70 w-100 overflow-hidden">
                            <Role role={role} />
                        </div>
                        <div className="relative h-30 w-100 overflow-hidden">
                            <DrawPile draw={draw} setDraw={setDraw} socket={socket} />
                        </div>
                    </div>

                    <div className="relative d-inlineblock h-100x hitlerCenter">
                        <div className="relative h-50 w-100 overflow-hidden">
                            <LiberalBoard board={liberalBoard} />
                        </div>
                        <div className="relative h-50 w-100 overflow-hidden">
                            <FascistBoard board={fascistBoard} />
                        </div>
                    </div>

                    <div className="relative d-inlineblock h-100x hitlerSidebar">
                        <div className="relative h-70 w-100 overflow-hidden">
                            <Membership membership={membership} />
                        </div>
                        <div className="relative h-30 w-100 overflow-hidden"></div>
                    </div>
                </div>
            </div>
            <PlayerSelect playerSelect={playerSelect} setPlayerSelect={setPlayerSelect} socket={socket} />
            <PolicySelect policySelect={policySelect} setPolicySelect={setPolicySelect} socket={socket} />
            <Vote voteSelect={voteSelect} setVoteSelect={setVoteSelect} socket={socket} />
            <ElectionResults electionResults={electionResults} />
            <Veto veto={veto} setVeto={setVeto} socket={socket} />
            <Examine examine={examine} />
            <Investigate investigate={investigate} />
            <Win win={win} setWin={setWin} socket={socket} />
            <PreGame pregame={pregame} socket={socket} />
            <RolePreview rolePreview={rolePreview} setRolePreview={setRolePreview} />
            <GameOptions currentOptions={options} socket={socket} />
        </div>
    );
}
 
export default Game;