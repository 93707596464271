import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import SideBar from '../common/sidebar/sidebar';
import Game from './game/game';

import './secretHitler.css';

const SecretHitler = ({ socket, username, room, setKick }) => {

    const [messages, setMessages] = useState([]);
    const [playerList, setPlayerlist] = useState([]);
    const [roomStats, setRoomStats] = useState({current: 0, max: 0});

    useEffect(() => {
        socket.emit('join', {username, room}, (error) => {
            if(error){
                setKick('errorJoiningRoom');
            }
        });
    }, [socket, room, username, setKick]);

    useEffect(() => {
        socket.on('chat', (msg) => {
            setMessages(messages => [...messages, msg]);
        });
    }, [socket]);

    useEffect(() => {
        socket.on('roomStats', ({playerList, roomStats}) => {
            setPlayerlist(playerList);
            setRoomStats(roomStats);
        });
    }, [socket]);

    useEffect(() => {
        socket.on('disconnect', () => {
            setKick('errorServerDisconnected');
        });
    }, [socket, setKick]);

    const sendMessage = (msg) => {
        socket.emit('sendChat', msg, () => {

        });
    }

    return (  
        <div>
            <div className="d-inline-block game">
                <Helmet><title>Secret Hitler</title></Helmet>
                <Game socket={socket} setKick={setKick} />
            </div>
            <SideBar code={room} playerList={playerList} roomStats={roomStats} messages={messages} sendMessage={sendMessage} />
        </div>
    );
}
 
export default SecretHitler;