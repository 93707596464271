import React from 'react';
import Player from './player/player';

import './playerList.css';

const PlayerList = ({ playerList, roomStats, theme, setCookie }) => {

    const changeTheme = () => {
        if(theme === 'light-theme'){
            setCookie('theme', 'dark-theme', { path: '/' });
        }
        else{
            setCookie('theme', 'light-theme', { path: '/' });
        }
    }

    return (  
        <div className="playerListContainer">
            <div className="playerList">
                <p className="d-inline">{roomStats.current}/{roomStats.max} Players Online</p>
                <button className="btn float-right theme-button" onClick={() => changeTheme()}><i className={theme==='light-theme' ? "far fa-moon" : "fas fa-moon"}></i></button>
                {playerList.map((item, i) => <Player key={i} player={item} />)}
            </div>
        </div>
    );
}
 
export default PlayerList;